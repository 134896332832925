/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import UploadImage from '@/components/UploadImage/UploadImageNew';
import atitle from '@/components/vis/Title02';
import Checker from 'vux/src/components/checker/checker';
import CheckerItem from 'vux/src/components/checker/checker-item';
import { Toast } from 'vant';
export default {
  name: 'CompleteVisitorInfo',
  components: {
    UploadImage: UploadImage,
    atitle: atitle,
    Checker: Checker,
    CheckerItem: CheckerItem
  },
  data: function data() {
    return {
      area: [],
      demo6: [2, 3],
      userInfo: {},
      visitor: '',
      //来访人姓名
      visitorTel: '',
      //来访人电话
      identity: '',
      //来访人身份证号
      kssj: '',
      //到访时间
      jssj: '',
      //结束时间
      reasonList: [],
      reason: [],
      //到访事由
      compName: '个人',
      //来访单位
      visCars: '',
      //随访车辆
      vispeoper: '',
      //随访人数
      remark: '',
      //备注
      xh: '',
      //新增预约成功后返回的序号
      visUserSerial: '',
      //被访人序号
      startDate: '',
      //开始日期
      curTime: '',
      //当前时间
      icon: 'icon-renlian',
      //icon人脸
      limitNum: 1,
      //上传的图片张数
      imgList: [],
      //图片列表数组
      docmHeight: document.documentElement.clientHeight,
      // 默认屏幕高度
      showHeight: document.documentElement.clientHeight,
      // 实时屏幕高度
      hidShow: true,
      // 显示或者隐藏footer
      checkerList: [],
      visInfo: {},
      //根据访客手机号码获取信息
      showPicker: false,
      showPickerstart: false,
      showPickerend: false,
      nowTime: '',
      ksTime: '',
      jsTime: '',
      reasonName: ''
    };
  },
  computed: {},
  methods: {
    showpopuptimestart: function showpopuptimestart() {
      this.kssj = this.curTime;

      if (!this.showPickerstart) {
        this.showPickerstart = true;
      } else {
        this.showPickerstart = false;
      }
    },
    showpopuptimeend: function showpopuptimeend() {
      this.jssj = this.curTime;

      if (!this.showPickerend) {
        this.showPickerend = true;
      } else {
        this.showPickerend = false;
      }
    },
    Confirmstart: function Confirmstart(value) {
      this.ksTime = value;
      var d = new Date(value);
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      var day = d.getDate();
      var hours = d.getHours();
      var minutes = d.getMinutes();

      if (month < 10) {
        month = '0' + month;
      }

      if (day < 10) {
        day = '0' + day;
      }

      if (hours < 10) {
        hours = '0' + hours;
      }

      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      this.kssj = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
      this.showPickerstart = false;
    },
    onCancelstart: function onCancelstart() {
      this.showPickerstart = false;
    },
    Confirmend: function Confirmend(value) {
      this.jsTime = value;
      var d = new Date(value);
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      var day = d.getDate();
      var hours = d.getHours();
      var minutes = d.getMinutes();

      if (month < 10) {
        month = '0' + month;
      }

      if (day < 10) {
        day = '0' + day;
      }

      if (hours < 10) {
        hours = '0' + hours;
      }

      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      this.jssj = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
      this.showPickerend = false;
    },
    onCancelend: function onCancelend() {
      this.showPickerend = false;
    },
    onConfirm: function onConfirm(item) {
      this.reason = item.value;
      this.reasonName = item.name; // this.value = item.value;

      this.showPicker = false;
    },
    saveImages: function saveImages() {},
    getDay: function getDay(day) {
      var today = new Date(this.startDate);
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码

      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    doHandleMonth: function doHandleMonth(month) {
      var m = month;

      if (month.toString().length == 1) {
        m = "0" + month;
      }

      return m;
    },

    /*
     /visRegisterNew/time-setting 新增预约时间限制
    */
    getVisitDate: function getVisitDate() {
      var _this = this;

      this.$api.Visit.getVistorTimeStting().then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          Toast(msg); // this.$utils.Tools.toastShow(msg);
        } else {
          //1设置时间 0 当前时间
          if (data.switchOn == 1) {
            _this.kssj = _this.startDate + " " + data.beginTime;
            _this.jssj = _this.startDate + " " + data.endTime;
          } else {
            _this.kssj = _this.curTime;
            _this.jssj = _this.startDate + " " + data.endTime;
          }
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    getSysDate: function getSysDate() {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          Toast(msg); // this.$utils.Tools.toastShow(msg);
        } else {
          _this2.startDate = data.sysDate.substring(0, 10);
          _this2.curTime = data.sysDate.substring(0, 16);

          _this2.getVisitDate();
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    changeReason: function changeReason(val) {
      this.reason = val;
    },
    getUserInfo: function getUserInfo() {
      var _this3 = this;

      this.$utils.Tools.loadShow();
      this.$api.User.getUserInfo(this.$utils.Store.getItem('userSerial')).then(function (response) {
        _this3.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          Toast(msg); //   this.$utils.Tools.toastShow(msg);
        } else {
          _this3.userInfo = data;
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    // 根据身份证获取访客的信息
    getVisInfo: function getVisInfo(id) {
      var _this4 = this;

      this.$utils.Tools.loadShow();
      this.$api.Visit.selectByCardNo({
        keys: id
      }).then(function (response) {
        _this4.$utils.Tools.loadHide();

        var code = response.code,
            data = response.data;

        if (+code !== _this4.$code.success) {//this.$utils.Tools.toastShow(msg);
        } else {
          _this4.visInfo = data.visUserEntity;
          _this4.visitor = _this4.visInfo.visLname;
          _this4.visitorTel = _this4.visInfo.visTelephone;
          _this4.identity = _this4.visInfo.visId;
          _this4.visCars = data.visRegisterEntity.visCars;

          if (data.visUserEntity.facePhoto) {
            _this4.imgList.push({
              src: data.visUserEntity.facePhoto,
              msrc: data.visUserEntity.facePhoto
            });
          }
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },

    /**
     *  function获取来访事由列表
     *  @wzx
     *  @2019-03-30
     **/
    getReasonList: function getReasonList() {
      var _this5 = this;

      this.$utils.Tools.loadShow();
      this.$api.Visit.getReasonList({
        lx: 0
      }).then(function (response) {
        _this5.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this5.$code.success) {
          Toast(msg);
        } else {
          var _iterator = _createForOfIteratorHelper(data),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var item = _step.value;

              _this5.reasonList.push({
                value: JSON.stringify(item.xh),
                name: item.thingName,
                text: item.thingName
              }); // this.reasonList.push({ defaultIndex: JSON.stringify(item.xh), values: item.thingName })

            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
      }).catch(function () {
        _this5.$utils.Tools.httpError();
      });
    },

    /**
     *  function新增访客预约信息
     *  @wzx
     *  @2019-03-30
     **/
    addVisitorOrder: function addVisitorOrder() {
      var _this6 = this;

      this.$utils.Tools.loadShow(); // return;

      var facePhoto = '';

      if (this.imgList.length > 0) {
        if (this.imgList[0].serverId) {
          facePhoto = this.imgList[0].serverId;
        } else if (this.imgList[0].src) {
          facePhoto = '-1';
        }
      }

      var nolist = [];
      this.checkerList.some(function (item) {
        nolist.push(_this6.area[item].areaNo);
      });
      nolist = nolist.join();

      if (this.$params.overtimeMeals == 0) {
        this.$api.Visit.addVisitorOrder({
          type: '1',
          visRegisterEntity: {
            approveState: 3,
            userSerial: this.$utils.Store.getItem('userSerial'),
            visBz: this.remark,
            visKssj: this.kssj,
            visJssj: this.jssj,
            visThing: this.reason[0],
            visLx: 1,
            visOk: 2,
            //邀请而来
            visNums: this.vispeoper,
            //随访人数
            visArea: nolist,
            visCars: this.visCars
          },
          visUserEntity: {
            visId: this.identity,
            facePhoto: facePhoto,
            visLname: this.visitor,
            visTelephone: this.visitorTel,
            compName: this.compName,
            visFlag: 3
          }
        }).then(function (response) {
          _this6.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg,
              data = response.data;

          if (+code !== _this6.$code.success) {
            if (code === 610) {
              Toast('访问被拒绝'); // this.$utils.Tools.toastShow('访问被拒绝');
            } else {
              Toast(msg); //   this.$utils.Tools.toastShow(msg);
            }
          } else {
            _this6.xh = data.visRegisterEntity.xh;

            _this6.$router.replace({
              path: '/InvitationResult',
              query: {
                xh: _this6.xh
              }
            });
          }
        }).catch(function () {
          _this6.$utils.Tools.httpError();
        });
      } else {
        this.$api.Visit.addVisitorOrders({
          type: '1',
          visRegisterEntity: {
            approveState: 3,
            userSerial: this.$utils.Store.getItem('userSerial'),
            visBz: this.remark,
            visKssj: this.kssj,
            visJssj: this.jssj,
            visThing: this.reason[0],
            visLx: 1,
            visOk: 2,
            //邀请而来
            visArea: nolist,
            visCars: this.visCars,
            cfmType: 0
          },
          visUserEntity: {
            visId: this.identity,
            facePhoto: facePhoto,
            visLname: this.visitor,
            visTelephone: this.visitorTel,
            compName: this.compName,
            visFlag: 3
          }
        }).then(function (response) {
          _this6.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg,
              data = response.data;

          if (+code !== _this6.$code.success) {
            if (code === 610) {
              Toast('访问被拒绝'); // this.$utils.Tools.toastShow('访问被拒绝');
            } else {
              Toast(msg); //   this.$utils.Tools.toastShow(msg);
            }
          } else {
            _this6.xh = data.visRegisterEntity.xh;

            _this6.$router.replace({
              path: '/RegisterResult',
              query: {
                state: '0'
              }
            }); //等待,state必须为string类型
            // this.$router.replace({
            //   path: '/InvitationResult', query: {
            //     xh: this.xh,
            //   },
            // });

          }
        }).catch(function () {
          _this6.$utils.Tools.httpError();
        });
      }
    },
    registerClick: function registerClick() {
      if (this.$utils.RegExp.checkSpechars(this.visitor) || this.$utils.RegExp.checkEmpty(this.visitor)) {
        Toast('来访人姓名不能为空或包含特殊字符');
      } else if (!this.$utils.RegExp.checkIdCard(this.identity)) {
        Toast('来访人身份证号不正确或为空');
      } else if (this.$utils.RegExp.checkPhone(this.visitorTel)) {
        Toast('来访人手机号格式不正确或为空');
      } else if (this.$utils.RegExp.checkEmpty(this.kssj)) {
        Toast('预约开始时间不能为空'); // } else if (this.kssj < this.curTime) {
        //   this.$utils.Tools.toastShow('预约开始时间不能小于当前时间');
      } else if (this.$utils.RegExp.checkEmpty(this.jssj)) {
        Toast('预约结束时间不能为空');
      } else if (this.jssj < this.curTime) {
        Toast('预约结束时间不能小于当前时间');
      } else if (this.jssj <= this.kssj) {
        Toast('预约结束时间必须大于预约开始时间');
      } else if (!this.compName) {
        Toast('来访单位不能为空');
      } else if (this.reason.length === 0) {
        Toast('来访事由不能为空');
      } else if (this.visCars && !this.$utils.RegExp.checkCarNO(this.visCars)) {
        Toast('随访车牌号不合法或为空');
      } // else if (this.remark && this.$utils.RegExp.checkSpechars(this.remark)) {
      //   this.$utils.Tools.toastShow('备注不能包含特殊字符');
      // }
      else {
        // imgList
        this.addVisitorOrder();
      }
    },
    manClick: function manClick(obj, index) {
      this.selected = index;
    },

    /**
     *  function获取访客区域
     *  @lwt
     *  @2020-08-01
     **/
    selectAllListByEntity: function selectAllListByEntity() {
      var _this7 = this;

      this.$utils.Tools.loadShow();
      this.$api.Visit.selectAllListByEntity({
        userNo: 'system'
      }).then(function (response) {
        _this7.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this7.$code.success) {
          Toast(msg);
        } else {
          _this7.area = data;

          for (var i = 0; i < data.length; i++) {
            _this7.checkerList.push(i);
          }
        }
      }).catch(function () {
        _this7.$utils.Tools.httpError();
      });
    },
    // 压缩图片
    image2Base64: function image2Base64(img) {
      var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.7;
      var canvas = document.createElement('canvas');
      var scale = 1;

      if (img.width > 1280 || img.height > 1280) {
        if (img.width > img.height) {
          scale = 1280 / img.width;
        } else {
          scale = 1280 / img.height;
        }
      }

      if (scale != 1) {
        //按最大高度等比缩放
        img.width *= scale;
        img.height *= scale;
      }

      canvas.width = img.width;
      canvas.height = img.width * (img.height / img.width);
      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      var dataURL = canvas.toDataURL('image/jpeg', size);

      if (dataURL.length / 1024 > 10000) {
        //如果输出大于1m 就递归直到输出小于1M才行
        return this.image2Base64(img, (size / 2).toFixed(1) * 1);
      } else {
        return dataURL;
      }
    } //  ----

  },
  watch: {
    showHeight: function showHeight() {
      if (this.docmHeight > this.showHeight) {
        this.hidShow = false;
      } else {
        this.hidShow = true;
      }
    }
  },
  created: function created() {
    this.getSysDate();
    this.getReasonList();
    this.getUserInfo();
    this.selectAllListByEntity();
    this.nowTime = new Date();
  },
  mounted: function mounted() {
    var _this8 = this;

    // window.resize监听页面高度的变化
    window.onresize = function () {
      return function () {
        _this8.showHeight = document.body.clientHeight;
      }();
    };
  },
  destroyed: function destroyed() {}
};